

.contact__whatsApp_logo {
  padding-top: 8px; 
  height: 50px;
  width: 45px;
}

.contact__whatsApp_logo:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

/* WhatsApp Floating Icon */

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:25px;
	right:25px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #333333;
  /* z-index:100; */
	z-index: 9998!important;
}

@media screen and (max-width: 768px) {
	.contact__whatsApp_logo {
		padding-top: 8px; 
		height: 40px;
		width: 35px;
	}

	.float {
		width:50px;
	height:50px;
	bottom:20px;
	right:20px;
	}
}