
/* CSS for header */
.header {
  height: 100px;
  margin-top: 32px; 
} 

/* set it top right */
.logo {
  position: absolute;
  top: 0; 
  right: 10px; 
  margin: 1rem; 
  cursor: pointer;
}

/* Media Query for Mobile */
@media only screen and (max-width: 768px) {
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.logo {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

}
.logoImg {
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
}

/* Media Query for Tablet */
@media only screen and (min-width: 768px) and (max-width:1023px) {

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
  }

  .logo {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  
  }

  .logoImg {
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}

/* Media Query for Tablet */
@media only screen and (min-width: 1024px) and (max-width:1300px) {
  .header {  
    height: 100px;
  }
}


