/* Custom font for Contacto*/
@font-face {
  font-family: "65 Medium";
  src: url("../../assets/fonts/helveticaMedium/HelveticaNeue-Medium\ .otf");
}

/* CSS for manifiesto page */
.container__manifiesto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80vh;

}

.container__title {

  margin-left: 200px;
  margin-right: auto;

}

.manifiesto__title {

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  text-align: right;

  color: #F0F0F0;

}

/* Css for manifiesto's text */

.container__manifiesto__text {
  margin-top: 100px;
  width: 644px;
  margin-left: auto;
  margin-right: auto;
}

.manifiesto__text {

  font-family: "45 Light";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: justify;

  color: #F0F0F0;

}

/* Media Query for Mobile */
@media only screen and (max-width:768px) {


  .container__manifiesto {
    margin-top: 40px;
  }

  .manifiesto__title {

    font-family: "65 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #F0F0F0;

  }

  .container__manifiesto__text {
    width: 90%;
  }

  .manifiesto__text {

    font-family: "45 Light";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: justify;

    color: #F0F0F0;

  }
}

/* Media Query for Tablet */
@media only screen and (min-width: 768px) and (max-width:1023px) {
  .container__manifiesto {
    margin-top: 60px;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
  }

  .container__manifiesto__text{
    width: 100%;
  }

  .manifiesto__title {

    font-family: "65 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 36px;
    text-align: center;

    color: #F0F0F0;

  }

  .container__manifiesto__text {
    margin-top: 200px;
  }

  .manifiesto__text {

    font-family: "45 Light";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: justify;

    color: #F0F0F0;

  }
}